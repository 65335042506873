import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { CartContext } from '../contexts/CartContext';
import VariationSelector from '../components/VariationSelector';
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Divider,
  TextField,
  Breadcrumbs,
  Link,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Share as ShareIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import RelatedProductsCarousel from '../components/RelatedProductsCarousel';
import ReviewSection from '../components/ReviewSection';
import SpecificationsTable from '../components/SpecificationsTable';
import QuestionAnswerSection from '../components/QuestionAnswerSection';

// Styled Components for consistent styling
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  background: 'linear-gradient(135deg, #fff0f6 0%, #fce4ec 100%)',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffffcc',
}));

const StyledButton = styled(Box)(({ theme }) => ({
  backgroundColor: '#ff80ab',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff4081',
  },
  padding: theme.spacing(1, 4),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontSize: '1rem',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
}));

function ProductDetailPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { user } = useContext(UserContext);
  const { cart, setCart } = useContext(CartContext);
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Snackbar State for user feedback
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
    message: '',
  });

  // Tabs State to manage active tab
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();

  // Fetch product details and user's favorites on component mount
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://lemda.co.uk/dubai_api/get_product.php?id=${id}`
        );
        setProduct(response.data);
        setLoading(false);
        if (response.data.variations && response.data.variations.length > 0) {
          setSelectedVariation(response.data.variations[0]);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(true);
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      if (user) {
        try {
          const response = await axios.get(
            `https://lemda.co.uk/dubai_api/get_favorites.php?user_id=${user.id}`
          );
          const favoriteProductIds = response.data.map((p) => p.id);
          setIsFavorite(favoriteProductIds.includes(parseInt(id, 10)));
        } catch (error) {
          console.error('Error fetching favorites:', error);
        }
      }
    };

    fetchProduct();
    fetchFavorites();
  }, [id, user]);

  // Handle adding/removing favorites (wishlist)
  const handleFavoriteToggle = async () => {
    if (!user) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Please log in to manage favorites.',
      });
      return;
    }

    const url = isFavorite
      ? `https://lemda.co.uk/dubai_api/remove_from_favorites.php`
      : `https://lemda.co.uk/dubai_api/add_to_favorites.php`;

    try {
      const response = await axios.post(url, {
        user_id: user.id,
        product_id: product.id,
      });

      if (response.data.success) {
        setIsFavorite(!isFavorite);
        setSnackbar({
          open: true,
          severity: 'success',
          message: isFavorite
            ? 'Removed from wishlist.'
            : 'Added to wishlist!',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: `Error: ${response.data.error || 'Unknown error.'}`,
        });
      }
    } catch (error) {
      console.error('Error updating favorites:', error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'An error occurred while updating wishlist.',
      });
    }
  };

  // Handle adding to cart
  const addToCart = () => {
    if (quantity < 1) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Please select a valid quantity.',
      });
      return;
    }

    const existingItemIndex = cart.findIndex(
      (item) =>
        item.productId === product.id &&
        item.variationId === (selectedVariation ? selectedVariation.id : null)
    );

    if (existingItemIndex !== -1) {
      // If item exists, update quantity
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += quantity;
      setCart(updatedCart);
    } else {
      // If item doesn't exist, add new item
      const item = {
        productId: product.id,
        variationId: selectedVariation ? selectedVariation.id : null,
        name: product.name,
        price: selectedVariation
          ? parseFloat(selectedVariation.price)
          : parseFloat(product.base_price),
        quantity,
        image:
          selectedVariation && selectedVariation.image
            ? 'https://lemda.co.uk/dubai_api/uploads/' + selectedVariation.image
            : 'https://lemda.co.uk/dubai_api/uploads/' + product.image,
      };
      setCart([...cart, item]);
    }

    setSnackbar({
      open: true,
      severity: 'success',
      message: 'Added to cart!',
    });
  };

  // Handle Snackbar Close
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Share Functionality (Simplified)
  const handleShare = () => {
    const shareData = {
      title: product.name,
      text: product.short_description,
      url: window.location.href,
    };

    navigator.share
      ? navigator.share(shareData).catch((err) => console.error('Error sharing:', err))
      : alert('Share not supported in this browser.');
  };

  // Handle Quantity Input Change with Validation
  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 1) {
      setQuantity(1);
    } else {
      setQuantity(value);
    }
  };

  // Calculate Unit Price
  const getUnitPrice = () => {
    return selectedVariation
      ? parseFloat(selectedVariation.price)
      : parseFloat(product.base_price);
  };

  // Calculate Total Price based on quantity and unit price
  const getTotalPrice = () => {
    return (getUnitPrice() * quantity).toFixed(2);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !product) {
    return (
      <StyledContainer maxWidth="md">
        <Alert severity="error">
          Error fetching product details. Please try again later.
        </Alert>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer maxWidth="lg">
      {/* Breadcrumbs for Navigation */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link component={RouterLink} to="/" sx={{ color: '#ff80ab' }}>
          Home
        </Link>
        <Link component={RouterLink} to="/products" sx={{ color: '#ff80ab' }}>
          Products
        </Link>
        <Typography color="text.primary">{product.name}</Typography>
      </Breadcrumbs>

      <Grid container spacing={4}>
        {/* Product Image */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={`https://lemda.co.uk/dubai_api/uploads/${product.image}`}
            alt={product.name}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              objectFit: 'cover',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          />
        </Grid>

        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            {/* Product Title and Wishlist Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  flexGrow: 1,
                  fontFamily: 'Playfair Display, serif',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                {product.brand} - {product.name}
              </Typography>
              <IconButton
                onClick={handleFavoriteToggle}
                aria-label={isFavorite ? 'Remove from wishlist' : 'Add to wishlist'}
                sx={{
                  color: isFavorite ? '#ff4081' : '#bdbdbd',
                  '&:hover': {
                    color: '#ff4081',
                  },
                }}
              >
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Box>

            {/* Product Ratings */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              {[1, 2, 3, 4, 5].map((star) => (
                <StarIcon
                  key={star}
                  sx={{
                    color: star <= product.rating ? '#ffca28' : '#e0e0e0',
                  }}
                />
              ))}
              <Typography variant="body2" sx={{ ml: 1 }}>
                ({product.review_count} reviews)
              </Typography>
            </Box>

            {/* Unit Price */}
            <Typography
              variant="h5"
              sx={{ mb: 1, color: '#ff4081', fontWeight: 'bold' }}
              component="div"
            >
              Unit Price: LKR {getUnitPrice().toFixed(2)}
            </Typography>

            {/* Total Price */}
            <Typography
              variant="h6"
              sx={{ mb: 2, color: '#d81b60' }}
              component="div"
            >
              Total Price: LKR {getTotalPrice()}
            </Typography>

            {/* Short Description */}
            <Typography variant="body1" sx={{ mb: 3, color: '#555' }}>
              {product.short_description}
            </Typography>

            {/* Variation Selector */}
            {product.variations && product.variations.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Select Variation:
                </Typography>
                <VariationSelector
                  variations={product.variations}
                  selectedVariation={selectedVariation}
                  setSelectedVariation={setSelectedVariation}
                />
              </Box>
            )}

            {/* Quantity Selector and Add to Cart Button */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <TextField
                label="Quantity"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                value={quantity}
                onChange={handleQuantityChange}
                sx={{ width: '100px', mr: 2 }}
                variant="outlined"
              />
              <StyledButton onClick={addToCart}>
                <AddShoppingCartIcon sx={{ mr: 1 }} />
                Add to Cart
              </StyledButton>
              <IconButton
                sx={{
                  ml: 1,
                  color: '#757575',
                  '&:hover': {
                    color: '#3f51b5',
                  },
                }}
                onClick={handleShare}
                aria-label="Share product"
              >
                <ShareIcon />
              </IconButton>
            </Box>

            {/* Additional Product Information */}
            <Typography variant="body2" color="text.secondary">
              SKU: {product.sku}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Category: {product.category}
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Tabs for Additional Information */}
      <Box sx={{ mt: 4 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="product details tabs"
          textColor="primary"
          indicatorColor="primary"
          sx={{
            '& .MuiTab-root': {
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Playfair Display, serif',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff80ab',
            },
          }}
        >
          <Tab label="Description" />
          <Tab label="Specifications" />
          <Tab label="Reviews" />
          <Tab label="Q & A" />
        </Tabs>
        <Divider />

        {tabIndex === 0 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Product Description
            </Typography>
            <Typography variant="body1" sx={{ color: '#555' }}>
              {product.description}
            </Typography>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Specifications
            </Typography>
            <SpecificationsTable specifications={product.specifications} />
          </Box>
        )}
        {tabIndex === 2 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Customer Reviews
            </Typography>
            <ReviewSection productId={product.id} />
          </Box>
        )}
        {tabIndex === 3 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Questions & Answers
            </Typography>
            <QuestionAnswerSection productId={product.id} />
          </Box>
        )}
      </Box>

      {/* Related Products Carousel */}
      <Box sx={{ mt: 6 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontFamily: 'Playfair Display, serif', color: '#333', mb: 2 }}
        >
          Related Products
        </Typography>
        <RelatedProductsCarousel
          categoryId={product.category_id}
          currentProductId={product.id}
        />
      </Box>

      {/* Snackbar for User Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default ProductDetailPage;
