// src/pages/CartPage.js
import React, { useContext, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const TotalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(2),
}));

function CartPage() {
  const { cart, setCart } = useContext(CartContext);
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success', // 'success' | 'error'
    message: '',
  });

  const [dialog, setDialog] = useState({
    open: false,
    itemId: null,
  });

  // Handle Snackbar Close
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Handle Dialog Open
  const handleOpenDialog = (id) => {
    setDialog({
      open: true,
      itemId: id,
    });
  };

  // Handle Dialog Close
  const handleCloseDialog = () => {
    setDialog({
      open: false,
      itemId: null,
    });
  };

  // Handle Remove Item
  const handleRemoveItem = () => {
    const updatedCart = cart.filter((item) => item.id !== dialog.itemId);
    setCart(updatedCart);
    setSnackbar({
      open: true,
      severity: 'success',
      message: 'Item removed from cart.',
    });
    handleCloseDialog();
  };

  // Handle Quantity Change
  const handleQuantityChange = (id, delta) => {
    const updatedCart = cart.map((item) => {
      if (item.id === id) {
        const newQuantity = item.quantity + delta;
        return {
          ...item,
          quantity: newQuantity > 0 ? newQuantity : 1,
        };
      }
      return item;
    });
    setCart(updatedCart);
  };

  // Safely parse price and ensure it's a number
  const parsePrice = (price) => {
    const parsed = parseFloat(price);
    return isNaN(parsed) ? 0 : parsed;
  };

  // Calculate Total
  const calculateTotal = () => {
    return cart
      .reduce((sum, item) => sum + parsePrice(item.price) * item.quantity, 0)
      .toFixed(2);
  };

  // Proceed to Checkout
  const handleCheckout = () => {
    // Implement your checkout logic here
    navigate('/checkout');
  };

  return (
    <StyledContainer maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Your Shopping Cart
      </Typography>
      {cart.length === 0 ? (
        <Typography variant="h6">Your cart is empty.</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="cart table">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="right">Subtotal</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Grid container alignItems="center" spacing={2}>
                        {/* <Grid item>
                          <img
                            src={item.imageUrl}
                            alt={item.name}
                            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                          />
                        </Grid> */}
                        <Grid item>
                          <Typography variant="subtitle1">{item.name}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      LKR {parsePrice(item.price).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="decrease quantity"
                        onClick={() => handleQuantityChange(item.id, -1)}
                        size="small"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body1" component="span" sx={{ mx: 1 }}>
                        {item.quantity}
                      </Typography>
                      <IconButton
                        aria-label="increase quantity"
                        onClick={() => handleQuantityChange(item.id, 1)}
                        size="small"
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      LKR {(parsePrice(item.price) * item.quantity).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="remove item"
                        onClick={() => handleOpenDialog(item.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Total and Checkout */}
          <TotalBox>
            <Box>
              <Typography variant="h6">Total: LKR {calculateTotal()}</Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCheckout}
                sx={{ mt: 2 }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </TotalBox>

          {/* Confirmation Dialog */}
          <Dialog
            open={dialog.open}
            onClose={handleCloseDialog}
            aria-labelledby="remove-item-dialog-title"
            aria-describedby="remove-item-dialog-description"
          >
            <DialogTitle id="remove-item-dialog-title">Remove Item</DialogTitle>
            <DialogContent>
              <DialogContentText id="remove-item-dialog-description">
                Are you sure you want to remove this item from your cart?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleRemoveItem} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for Feedback */}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{ width: '100%' }}
              variant="filled"
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </>
      )}
    </StyledContainer>
  );
}

export default CartPage;
