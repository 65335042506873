// src/pages/FavoritesPage.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import ProductCard from '../components/ProductCard';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Button,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontFamily: 'Playfair Display, serif',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff4081',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#f50057',
  },
  marginLeft: theme.spacing(2),
}));

// Snackbar Alert Component
const AlertComponent = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FavoritesPage() {
  const { user } = useContext(UserContext);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar state

  useEffect(() => {
    if (user) {
      axios
        .get(`https://lemda.co.uk/dubai_api/get_favorites.php?user_id=${user.id}`)
        .then((response) => {
          if (Array.isArray(response.data)) {
            setFavorites(response.data);
          } else {
            setError('Unexpected response format.');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching favorites:', error);
          setError('An error occurred while fetching favorites.');
          setLoading(false);
        });
    } else {
      setError('Please log in to view your favorites.');
      setLoading(false);
    }
  }, [user]);

  // Handler to remove a favorite (Assuming API exists)
  const handleRemoveFavorite = (productId) => {
    axios
      .post('https://lemda.co.uk/dubai_api/remove_favorite.php', {
        user_id: user.id,
        product_id: productId,
      })
      .then((response) => {
        if (response.data.success) {
          setFavorites((prevFavorites) =>
            prevFavorites.filter((product) => product.id !== productId)
          );
          setSnackbar({ open: true, message: 'Removed from favorites.', severity: 'success' });
        } else {
          setSnackbar({ open: true, message: response.data.error || 'Failed to remove favorite.', severity: 'error' });
        }
      })
      .catch((error) => {
        console.error('Error removing favorite:', error);
        setSnackbar({ open: true, message: 'An error occurred while removing the favorite.', severity: 'error' });
      });
  };

  return (
    <StyledContainer maxWidth="lg">
      <Header variant="h4" align="center">
        Your Favorites
      </Header>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity={user ? 'error' : 'warning'}>{error}</Alert>
      ) : favorites.length === 0 ? (
        <Alert severity="info">You have no favorite products yet.</Alert>
      ) : (
        <Grid container spacing={4}>
          {favorites.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <ProductCard product={product}>
                {/* Optional: Add actions like Remove from Favorites */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                  <ActionButton
                    variant="contained"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleRemoveFavorite(product.id)}
                  >
                    Remove
                  </ActionButton>
                </Box>
              </ProductCard>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertComponent onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </AlertComponent>
      </Snackbar>
    </StyledContainer>
  );
}

export default FavoritesPage;
