// src/pages/ProductsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  CircularProgress,
  Alert,
  Slider,
  InputAdornment,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ProductCard from '../components/ProductCard';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'; // Import useLocation

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  background: 'linear-gradient(135deg, #fff0f6 0%, #fce4ec 100%)',
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#ffffffcc',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const StyledFilterTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontFamily: 'Playfair Display, serif',
}));

const StyledProductGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
}));

function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Changed from boolean to string/null

  // Filter states
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [sortOption, setSortOption] = useState('');

  // For dynamic filter options
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  // React Router's useLocation hook to access URL parameters
  const location = useLocation();

  useEffect(() => {
    // Function to parse query parameters
    const getQueryParams = () => {
      const params = new URLSearchParams(location.search);
      return {
        category: params.get('category') || '',
      };
    };

    const { category } = getQueryParams();

    if (category) {
      setSelectedCategory(category);
    }

    // Fetch categories and brands for filters
    axios
      .get('https://lemda.co.uk/dubai_api/get_categories.php')
      .then((response) => {
        if (response.data.success) {
          setCategories(response.data.data);
        } else {
          console.error(response.data.error || 'Failed to fetch categories.');
          // Optionally set an error state if needed
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        // Optionally set an error state if needed
      });

    // Fetch products with optional category filtering
    const fetchProducts = () => {
      let url = 'https://lemda.co.uk/dubai_api/get_products.php';
      if (category) {
        url += `?category=${encodeURIComponent(category)}`;
      }

      axios
        .get(url)
        .then((response) => {
          if (response.data.success) {
            setProducts(response.data.data);
            setFilteredProducts(response.data.data);

            // Extract brands for filters
            const uniqueBrands = [
              ...new Set(response.data.data.map((product) => product.brand)),
            ];
            setBrands(uniqueBrands);
          } else {
            console.error(response.data.error || 'Failed to fetch products.');
            setError(response.data.error || 'Failed to fetch products.');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          setError(
            error.response?.data?.error ||
              error.message ||
              'An unexpected error occurred while fetching products.'
          );
          setLoading(false);
        });
    };

    fetchProducts();
  }, [location.search]);

  // Handle filtering
  useEffect(() => {
    let filtered = products;

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.brand.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Remove category filter if backend handles it
    /*
    if (selectedCategory) {
      filtered = filtered.filter((product) => product.category === selectedCategory);
    }
    */

    // Filter by brand
    if (selectedBrand) {
      filtered = filtered.filter((product) => product.brand === selectedBrand);
    }

    // Filter by price range
    filtered = filtered.filter((product) => {
      const price = parseFloat(product.base_price);
      return price >= priceRange[0] && price <= priceRange[1];
    });

    // Sort products
    if (sortOption === 'price_low_high') {
      filtered = filtered.sort((a, b) => parseFloat(a.base_price) - parseFloat(b.base_price));
    } else if (sortOption === 'price_high_low') {
      filtered = filtered.sort((a, b) => parseFloat(b.base_price) - parseFloat(a.base_price));
    } else if (sortOption === 'name_az') {
      filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === 'name_za') {
      filtered = filtered.sort((a, b) => b.name.localeCompare(a.name));
    }

    setFilteredProducts(filtered);
  }, [searchQuery, selectedBrand, priceRange, sortOption, products]);

  // Function to handle category selection from filters
  const handleCategorySelection = (category) => {
    // If the same category is clicked again, deselect it
    if (selectedCategory === category) {
      setSelectedCategory('');
      // Update URL to remove category parameter
      const params = new URLSearchParams(location.search);
      params.delete('category');
      window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
    } else {
      setSelectedCategory(category);
      // Update URL to include the selected category
      const params = new URLSearchParams(location.search);
      params.set('category', category);
      window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
    }
  };

  return (
    <StyledContainer maxWidth="lg">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Playfair Display, serif',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        Our Premium Perfumes
      </Typography>

      <Grid container spacing={4}>
        {/* Sidebar Filters */}
        <Grid item xs={12} md={3}>
          <StyledSidebar>
            {/* Search Field */}
            <StyledFilterTitle variant="h6">Search</StyledFilterTitle>
            <TextField
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search perfumes..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />

            {/* Category Filter */}
            <StyledFilterTitle variant="h6">Category</StyledFilterTitle>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <List>
                {categories.map((category) => (
                  <ListItem
                    key={category.id}
                    dense
                    button
                    onClick={() => handleCategorySelection(category.name)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedCategory === category.name}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={category.name} />
                  </ListItem>
                ))}
              </List>
            </FormControl>

            {/* Brand Filter */}
            <StyledFilterTitle variant="h6">Brand</StyledFilterTitle>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <List>
                {brands.map((brand, index) => (
                  <ListItem
                    key={index}
                    dense
                    button
                    onClick={() => setSelectedBrand(selectedBrand === brand ? '' : brand)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedBrand === brand}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={brand} />
                  </ListItem>
                ))}
              </List>
            </FormControl>

            {/* Price Range Slider */}
            <StyledFilterTitle variant="h6">Price Range</StyledFilterTitle>
            <Typography gutterBottom>
              LKR {priceRange[0]} - LKR {priceRange[1]}
            </Typography>
            <Slider
              value={priceRange}
              onChange={(e, newValue) => setPriceRange(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={500}
              step={10}
              sx={{ mb: 2 }}
            />

            {/* Sort Options */}
            <StyledFilterTitle variant="h6">Sort By</StyledFilterTitle>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="price_low_high">Price: Low to High</MenuItem>
                <MenuItem value="price_high_low">Price: High to Low</MenuItem>
                <MenuItem value="name_az">Name: A to Z</MenuItem>
                <MenuItem value="name_za">Name: Z to A</MenuItem>
              </Select>
            </FormControl>
          </StyledSidebar>
        </Grid>

        {/* Products List */}
        <StyledProductGrid item xs={12} md={9}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : filteredProducts.length === 0 ? (
            <Alert severity="info">No products match your criteria.</Alert>
          ) : (
            <Grid container spacing={4}>
              {filteredProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          )}
        </StyledProductGrid>
      </Grid>
    </StyledContainer>
  );
}

export default ProductsPage;
