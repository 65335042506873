// src/components/Footer.js
import React from 'react';
import { Box, Container, Grid, Typography, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

function Footer() {
  return (
    <FooterContainer component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2">
              Perfume Store offers a wide range of premium fragrances from top brands around the
              world. Our mission is to provide the best scent experience for every individual.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              <FooterLink href="/about" display="block" gutterBottom>
                About Us
              </FooterLink>
              <FooterLink href="/contact" display="block" gutterBottom>
                Contact Us
              </FooterLink>
              <FooterLink href="/faq" display="block" gutterBottom>
                FAQ
              </FooterLink>
              <FooterLink href="/terms" display="block" gutterBottom>
                Terms & Conditions
              </FooterLink>
            </Box>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Get in Touch
            </Typography>
            <Typography variant="body2">Email: support@perfumestore.com</Typography>
            <Typography variant="body2">Phone: +1 (555) 123-4567</Typography>
            <Typography variant="body2">Address: 123 Main Street, Anytown, USA</Typography>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Perfume Store. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
