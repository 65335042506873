// src/components/AppNavbar.js
import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  InputBase,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  Zoom,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  Favorite as FavoriteIcon,
  AccountCircle,
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext';
import { styled, alpha, keyframes } from '@mui/material/styles';

// Animation for badge when cart count updates
const bounce = keyframes`
  0% { transform: scale(1); }
  30% { transform: scale(1.2); }
  50% { transform: scale(1); }
  100% { transform: scale(1); }
`;

// Styled Components
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
}));

const Search = styled('form')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#000000', 0.05), // Light background for search
  '&:hover': { backgroundColor: alpha('#000000', 0.1) },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: { width: 'auto' },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000000', // Dark text color
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: { width: '20ch' },
  },
}));

const AnimatedBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    animation: `${bounce} 0.6s ease-in-out`,
  },
}));

function AppNavbar() {
  const { cart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Calculate total cart items
  const totalCartItems = cart.reduce((acc, item) => acc + item.quantity, 0);

  // Handle cart count animation
  const [prevCartCount, setPrevCartCount] = useState(totalCartItems);

  useEffect(() => {
    if (prevCartCount !== totalCartItems) {
      setPrevCartCount(totalCartItems);
      // You can add additional effects here if needed
    }
  }, [totalCartItems, prevCartCount]);

  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements.search.value.trim();
    if (query) {
      navigate(`/products?search=${encodeURIComponent(query)}`);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/login');
    handleMenuClose();
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Navigation Links
  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Shop', path: '/products' },
    { title: "Men's Fragrances", path: '/products?category=Men' },
    { title: "Women's Fragrances", path: '/products?category=Women' },
    { title: 'Unisex Fragrances', path: '/products?category=Unisex' },
    { title: 'Limited Editions', path: '/products?category=Limited' },
    { title: 'Favorites', path: '/favorites' },
  ];

  // Determine active tab for MobileBottomNav
  const getActiveTab = () => {
    if (location.pathname === '/') return 'home';
    if (location.pathname.startsWith('/favorites')) return 'favorites';
    if (location.pathname.startsWith('/cart')) return 'cart';
    if (location.pathname.startsWith('/profile')) return 'profile';
    return 'home';
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: '#ffffff', // White background
          color: '#000000', // Dark text
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
        }}
      >
        <StyledToolbar>
          {/* Mobile Menu Icon */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <Box
            component={RouterLink}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="https://lemda.co.uk/dubai_api/uploads/DUBAI-tr1-e1678953756420.png" // Replace with your actual logo URL
              alt="Perfume Store Logo"
              style={{ height: '60px', marginRight: '8px' }} // Adjust height and margin as needed
            />
          </Box>

          {/* Desktop Navigation Links */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {navLinks.slice(0, 2).map((link) => (
              <Button
                key={link.title}
                component={RouterLink}
                to={link.path}
                sx={{
                  color: '#000000', // Dark text
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)', // Light hover effect
                  },
                }}
              >
                {link.title}
              </Button>
            ))}

            {/* Categories Dropdown */}
            <Button
              aria-controls="categories-menu"
              aria-haspopup="true"
              onClick={(e) => setAnchorElUser(e.currentTarget)}
              sx={{
                color: '#000000', // Dark text
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)', // Light hover effect
                },
              }}
            >
              Categories
            </Button>
            <Menu
              id="categories-menu"
              anchorEl={anchorElUser}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleMenuClose}
            >
              {navLinks.slice(2, 6).map((link) => (
                <MenuItem
                  key={link.title}
                  component={RouterLink}
                  to={link.path}
                  onClick={handleMenuClose}
                  sx={{ color: '#000000' }} // Dark text
                >
                  {link.title}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Search Bar - Hidden on Mobile */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <Search onSubmit={handleSearch}>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#000000' }} />
              </SearchIconWrapper>
              <StyledInputBase
                name="search"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>

          {/* Icons - Hidden on Mobile */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Favorites */}
            <Tooltip title="Favorites" TransitionComponent={Zoom}>
              <IconButton
                component={RouterLink}
                to="/favorites"
                sx={{
                  color: '#000000',
                }}
                aria-label="favorites"
              >
                <FavoriteIcon />
              </IconButton>
            </Tooltip>

            {/* Cart */}
            <Tooltip title="Cart" TransitionComponent={Zoom}>
              <IconButton
                component={RouterLink}
                to="/cart"
                sx={{ ml: 1, color: '#000000' }}
                aria-label="cart"
              >
                <AnimatedBadge
                  badgeContent={totalCartItems}
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <ShoppingCartIcon />
                </AnimatedBadge>
              </IconButton>
            </Tooltip>

            
 
           
          </Box>
        </StyledToolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ display: { sm: 'none' } }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {navLinks.map((link) => (
              <ListItem
                button
                key={link.title}
                component={RouterLink}
                to={link.path}
              >
                <ListItemText primary={link.title} />
              </ListItem>
            ))}
          </List>
          <Divider />
          {/* Optionally, add Search Bar or Icons in Drawer */}
          <Box sx={{ p: 2 }}>
            {/* Search within Drawer */}
            <Search onSubmit={handleSearch}>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#000000' }} />
              </SearchIconWrapper>
              <StyledInputBase
                name="search"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            {/* Authentication Links */}
            {user ? (
              <>
                <Button
                  component={RouterLink}
                  to="/profile"
                  fullWidth
                  sx={{ mb: 1 }}
                  onClick={handleMenuClose}
                >
                  Profile
                </Button>
                <Button
                  component={RouterLink}
                  to="/orders"
                  fullWidth
                  sx={{ mb: 1 }}
                  onClick={handleMenuClose}
                >
                  Orders
                </Button>
                <Button
                  onClick={handleLogout}
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button
                  component={RouterLink}
                  to="/login"
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  Login
                </Button>
                <Button
                  component={RouterLink}
                  to="/register"
                  fullWidth
                >
                  Register
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AppNavbar;
