// src/pages/OrderConfirmationPage.js

import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

function OrderConfirmationPage() {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        // Fetch order details from backend
        const response = await axios.get(
          `https://lemda.co.uk/dubai_api/get_order_details.php?order_id=${orderId}`,
          { withCredentials: true } // Adjust based on your auth mechanism
        );

        if (response.data.error) {
          setError(true);
        } else {
          setOrder(response.data);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching order details:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) {
    return (
      <StyledContainer maxWidth="sm">
        <CircularProgress />
      </StyledContainer>
    );
  }

  if (error || !order) {
    return (
      <StyledContainer maxWidth="sm">
        <Alert severity="error">
          Failed to fetch order details. Please contact support.
        </Alert>
        <Button variant="contained" color="primary" component={RouterLink} to="/">
          Go to Home
        </Button>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Thank You for Your Order!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Your order ID is <strong>{order.id}</strong>.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We have received your order and it is now being processed. You will receive
        another email once your order has been shipped.
      </Typography>
      {order.payment_method === 'bank_transfer' && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Bank Transfer Instructions:</Typography>
          <Typography variant="body2" color="text.secondary">
            Please transfer the total amount of ${order.total_amount} to the following
            bank account:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Bank:</strong> ABC Bank
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Account Number:</strong> 123456789
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>IBAN:</strong> ABCD1234567890
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>SWIFT:</strong> ABCDEF123
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Please include your order ID in the transfer details for reference.
          </Typography>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/"
        sx={{ mt: 4 }}
      >
        Continue Shopping
      </Button>
    </StyledContainer>
  );
}

export default OrderConfirmationPage;
