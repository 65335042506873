// src/components/QuestionAnswerSection.js
import React from 'react';
import { Typography } from '@mui/material';

function QuestionAnswerSection({ productId }) {
  // Placeholder content
  return (
    <Typography variant="body1">
      Questions and answers will be displayed here.
    </Typography>
  );
}

export default QuestionAnswerSection;
