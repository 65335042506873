// src/pages/CheckoutPage.js

import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link as RouterLink } from 'react-router-dom'; // Correctly imported RouterLink
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Snackbar,
  Alert,
  Paper,
  Divider,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CreditCard, LocalShipping, Payment } from '@mui/icons-material';

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  background: 'linear-gradient(135deg, #fff0f6 0%, #fce4ec 100%)',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffffcc',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff80ab',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff4081',
  },
  padding: theme.spacing(1, 4),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontSize: '1rem',
}));

const steps = ['Shipping Address', 'Billing Address', 'Payment Method', 'Review Order'];

function CheckoutPage() {
  const { cart, setCart } = useContext(CartContext);
  const { user, loading: userLoading } = useContext(UserContext);
  const navigate = useNavigate();

  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const [useSameAddress, setUseSameAddress] = useState(true);

  const [paymentMethod, setPaymentMethod] = useState('cash_on_delivery');

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
    message: '',
  });

  const [activeStep, setActiveStep] = useState(0);

  // Handle Snackbar Close
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Calculate total amount
  const calculateTotal = () => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
  };

  // Auto-fill addresses if user data is available
  useEffect(() => {
    if (!userLoading && user) {
      // Auto-fill shipping address
      setShippingAddress({
        address: user.address || '',
        city: user.city || '',
        state: user.state || '',
        postal_code: user.postal_code || '',
        country: user.country || '',
      });

      // Auto-fill billing address if using the same address
      if (useSameAddress) {
        setBillingAddress({
          address: user.address || '',
          city: user.city || '',
          state: user.state || '',
          postal_code: user.postal_code || '',
          country: user.country || '',
        });
      }
    }
  }, [user, userLoading, useSameAddress]);

  // Sync billing address with shipping address when toggle is enabled
  useEffect(() => {
    if (useSameAddress) {
      setBillingAddress({ ...shippingAddress });
    }
  }, [useSameAddress, shippingAddress]);

  

  const handleSubmit = async () => {
    // Basic validation
    const {
      address: sAddress,
      city: sCity,
      state: sState,
      postal_code: sPostal,
      country: sCountry,
    } = shippingAddress;
    const {
      address: bAddress,
      city: bCity,
      state: bState,
      postal_code: bPostal,
      country: bCountry,
    } = billingAddress;
  
    if (
      !sAddress.trim() ||
      !sCity.trim() ||
      !sState.trim() ||
      !sPostal.trim() ||
      !sCountry.trim()
    ) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Please fill in all shipping address fields.',
      });
      return;
    }
  
    if (!useSameAddress) {
      if (
        !bAddress.trim() ||
        !bCity.trim() ||
        !bState.trim() ||
        !bPostal.trim() ||
        !bCountry.trim()
      ) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please fill in all billing address fields.',
        });
        return;
      }
    }
  
    if (cart.length === 0) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Your cart is empty.',
      });
      return;
    }
  
    try {
      const cartItems = cart.map((item) => ({
        product_id: item.product_id || item.productId,
        variation_id: item.variation_id || item.variationId || null,
        quantity: item.quantity,
        unit_price: item.unit_price || item.price,
      }));
  
      const payload = {
        user_id: user.id || user.user_id, // Ensure correct user ID
        shipping_address: {
          address: shippingAddress.address,
          city: shippingAddress.city,
          state: shippingAddress.state,
          postal_code: shippingAddress.postal_code,
          country: shippingAddress.country,
        },
        billing_address: useSameAddress
          ? {
              address: shippingAddress.address,
              city: shippingAddress.city,
              state: shippingAddress.state,
              postal_code: shippingAddress.postal_code,
              country: shippingAddress.country,
            }
          : {
              address: billingAddress.address,
              city: billingAddress.city,
              state: billingAddress.state,
              postal_code: billingAddress.postal_code,
              country: billingAddress.country,
            },
        payment_method: paymentMethod,
        cart_items: cartItems,
      };
  
      const response = await axios.post(
        'https://lemda.co.uk/dubai_api/checkout.php',
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.success) {
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Order placed successfully!',
        });
        // Redirect to order confirmation page
        setTimeout(() => {
          navigate(`/order-confirmation/${response.data.order_id}`);
        }, 2000);
        // Clear cart
        setCart([]);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: response.data.error || 'An error occurred during checkout.',
        });
      }
    } catch (error) {
      console.error('Checkout error:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: error.response.data.error,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'An unexpected error occurred. Please try again.',
        });
      }
    }
  };
  
  

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (userLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <StyledContainer maxWidth="md">
        <Alert severity="warning" sx={{ mb: 2 }}>
          Please{' '}
          <RouterLink to="/login" style={{ color: '#d81b60' }}>
            log in
          </RouterLink>{' '}
          to proceed with checkout.
        </Alert>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer maxWidth="md">
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontFamily: 'Playfair Display, serif', fontWeight: 'bold', color: '#333', mb: 4 }}
      >
        Checkout
      </Typography>

      {/* Stepper */}
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  color: '#ff80ab',
                  '&.Mui-active': { color: '#d81b60' },
                  '& .MuiStepIcon-text': { fill: '#fff' },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step Content */}
      {activeStep === 0 && (
        <Section elevation={3}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Shipping Address
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Address"
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                value={shippingAddress.address}
                onChange={(e) =>
                  setShippingAddress({ ...shippingAddress, address: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                fullWidth
                variant="outlined"
                value={shippingAddress.city}
                onChange={(e) =>
                  setShippingAddress({ ...shippingAddress, city: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State/Province"
                fullWidth
                variant="outlined"
                value={shippingAddress.state}
                onChange={(e) =>
                  setShippingAddress({ ...shippingAddress, state: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Postal Code"
                fullWidth
                variant="outlined"
                value={shippingAddress.postal_code}
                onChange={(e) =>
                  setShippingAddress({ ...shippingAddress, postal_code: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                fullWidth
                variant="outlined"
                value={shippingAddress.country}
                onChange={(e) =>
                  setShippingAddress({ ...shippingAddress, country: e.target.value })
                }
                required
              />
            </Grid>
          </Grid>
        </Section>
      )}

      {activeStep === 1 && (
        <Section elevation={3}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Billing Address
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <FormControlLabel
            control={
              <Checkbox
                checked={useSameAddress}
                onChange={(e) => setUseSameAddress(e.target.checked)}
                sx={{ color: '#ff80ab', '&.Mui-checked': { color: '#d81b60' } }}
              />
            }
            label="Use the same address for billing"
          />
          {!useSameAddress && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  value={billingAddress.address}
                  onChange={(e) =>
                    setBillingAddress({ ...billingAddress, address: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  fullWidth
                  variant="outlined"
                  value={billingAddress.city}
                  onChange={(e) =>
                    setBillingAddress({ ...billingAddress, city: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="State/Province"
                  fullWidth
                  variant="outlined"
                  value={billingAddress.state}
                  onChange={(e) =>
                    setBillingAddress({ ...billingAddress, state: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  fullWidth
                  variant="outlined"
                  value={billingAddress.postal_code}
                  onChange={(e) =>
                    setBillingAddress({ ...billingAddress, postal_code: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  fullWidth
                  variant="outlined"
                  value={billingAddress.country}
                  onChange={(e) =>
                    setBillingAddress({ ...billingAddress, country: e.target.value })
                  }
                  required
                />
              </Grid>
            </Grid>
          )}
        </Section>
      )}

      {activeStep === 2 && (
        <Section elevation={3}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Payment Method
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose a payment method</FormLabel>
            <RadioGroup
              aria-label="payment_method"
              name="payment_method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <FormControlLabel
                value="cash_on_delivery"
                control={<Radio sx={{ color: '#ff80ab', '&.Mui-checked': { color: '#d81b60' } }} />}
                label="Cash on Delivery"
              />
              <FormControlLabel
                value="bank_transfer"
                control={<Radio sx={{ color: '#ff80ab', '&.Mui-checked': { color: '#d81b60' } }} />}
                label="Bank Transfer"
              />
            </RadioGroup>
          </FormControl>
          {paymentMethod === 'bank_transfer' && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Please transfer the total amount of LKR {calculateTotal()} to the following bank
                account:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Bank:</strong> ABC Bank
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Account Number:</strong> 123456789
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>IBAN:</strong> ABCD1234567890
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>SWIFT:</strong> ABCDEF123
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Please include your order ID in the transfer details for reference.
              </Typography>
            </Box>
          )}
        </Section>
      )}

      {activeStep === 3 && (
        <Section elevation={3}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
            Review Order
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {/* Cart Summary */}
          {cart.map((item) => (
            <Box key={`${item.productId}-${item.variationId}`} sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.name ? `${item.name} Image` : 'Product Image'}
                    loading="lazy"
                    sx={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 1,
                      objectFit: 'cover',
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="subtitle1">{item.name}</Typography>
                  {item.variationId && (
                    <Typography variant="body2" color="text.secondary">
                      Variation ID: {item.variationId}
                    </Typography>
                  )}
                  <Typography variant="body1">Quantity: {item.quantity}</Typography>
                  <Typography variant="body1">
                    Price: LKR {item.price} x {item.quantity} = LKR {(item.price * item.quantity).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Total: LKR {calculateTotal()}</Typography>
        </Section>
      )}

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        {activeStep > 0 && (
          <StyledButton variant="contained" onClick={handleBack}>
            Back
          </StyledButton>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <StyledButton variant="contained" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
        </StyledButton>
      </Box>

      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default CheckoutPage;
