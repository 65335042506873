// src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import ImageGallery styles
import { styled } from '@mui/material/styles';

// Styled Components for Categories Section
const DarkCategoriesSection = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#1e1e1e', // Dark background
  padding: theme.spacing(6, 0),
  color: '#ffffff',
}));

const CategoryGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
}));

const CategoryCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '250px',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}));

const CategoryMedia = styled(CardMedia)(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const CategoryOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  background: 'rgba(0, 0, 0, 0.6)',
  padding: theme.spacing(1),
  textAlign: 'center',
}));

function HomePage() {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const [loadingLatest, setLoadingLatest] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [errorFeatured, setErrorFeatured] = useState(null);
  const [errorLatest, setErrorLatest] = useState(null);
  const [errorCategories, setErrorCategories] = useState(null);
  const [errorBrands, setErrorBrands] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Fetch featured products
    axios
      .get('https://lemda.co.uk/dubai_api/get_featured_products.php')
      .then((response) => {
        if (response.data.success) {
          setFeaturedProducts(response.data.data);
        } else {
          setErrorFeatured(response.data.error || 'Failed to fetch featured products.');
        }
        setLoadingFeatured(false);
      })
      .catch((error) => {
        setErrorFeatured(error.message || 'An error occurred while fetching featured products.');
        setLoadingFeatured(false);
        console.error('Error fetching featured products:', error);
      });

    // Fetch latest products
    axios
      .get('https://lemda.co.uk/dubai_api/get_latest_products.php')
      .then((response) => {
        if (response.data.success) {
          setLatestProducts(response.data.data);
        } else {
          setErrorLatest(response.data.error || 'Failed to fetch latest products.');
        }
        setLoadingLatest(false);
      })
      .catch((error) => {
        setErrorLatest(error.message || 'An error occurred while fetching latest products.');
        setLoadingLatest(false);
        console.error('Error fetching latest products:', error);
      });

    // Fetch categories
    axios
      .get('https://lemda.co.uk/dubai_api/get_categories.php') // Ensure this endpoint exists and returns data
      .then((response) => {
        if (response.data.success) {
          setCategories(response.data.data);
        } else {
          setErrorCategories(response.data.error || 'Failed to fetch categories.');
        }
        setLoadingCategories(false);
      })
      .catch((error) => {
        setErrorCategories(error.message || 'An error occurred while fetching categories.');
        setLoadingCategories(false);
        console.error('Error fetching categories:', error);
      });
  }, []);

  // Prepare images for ImageGallery
  const getHeroImages = () => {
    return [
      {
        original: 'https://lemda.co.uk/dubai_api/perfume.jpg',
        thumbnail: 'https://lemda.co.uk/dubai_api/perfume.jpg',
      },
      {
        original: 'https://lemda.co.uk/dubai_api/perfume.jpg',
        thumbnail: 'https://lemda.co.uk/dubai_api/perfume.jpg',
      },
      {
        original: 'https://lemda.co.uk/dubai_api/perfume.jpg',
        thumbnail: 'https://lemda.co.uk/dubai_api/perfume.jpg',
      },
    ];
  };

  // Handle Search Form Submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Redirect to products page with search query
      window.location.href = `/products?search=${encodeURIComponent(searchQuery.trim())}`;
    }
  };

  // Function to fetch brands based on selected category
  const fetchBrands = (categoryName) => {
    setLoadingBrands(true);
    setErrorBrands(null);
    axios
      .get(`https://lemda.co.uk/dubai_api/get_brands.php?category=${encodeURIComponent(categoryName)}`)
      .then((response) => {
        if (response.data.success) {
          setBrands(response.data.data);
        } else {
          setErrorBrands(response.data.error || 'Failed to fetch brands.');
        }
        setLoadingBrands(false);
      })
      .catch((error) => {
        setErrorBrands(error.message || 'An error occurred while fetching brands.');
        setLoadingBrands(false);
        console.error('Error fetching brands:', error);
      });
  };

  // Handle Category Selection
  const handleCategoryClick = (categoryName) => {
    if (selectedCategory === categoryName) {
      // Deselect category
      setSelectedCategory('');
      setSelectedBrand('');
      setBrands([]);
    } else {
      // Select new category
      setSelectedCategory(categoryName);
      setSelectedBrand('');
      setBrands([]);
      fetchBrands(categoryName);
    }
  };

  // Handle Brand Selection
  const handleBrandClick = (brandName) => {
    if (selectedBrand === brandName) {
      // Deselect brand
      setSelectedBrand('');
    } else {
      // Select new brand
      setSelectedBrand(brandName);
    }
  };

  return (
    <Box sx={{ mt: 0, mb: 4 }}>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: '#000',
          color: 'white',
          paddingY: { xs: 4, md: 10 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          mb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Hero Text */}
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h1" gutterBottom>
                Discover Your Signature Scent
              </Typography>
              <Typography variant="h6" gutterBottom>
                Explore our exclusive collection of premium perfumes.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/products"
                size="large"
                startIcon={<ShoppingCartIcon />}
                sx={{ mt: 2 }}
              >
                Shop Now
              </Button>
            </Grid>
            {/* Hero Image Gallery */}
            <Grid item xs={12} md={6}>
              <ImageGallery
                items={getHeroImages()}
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={true}
                autoPlay
                slideInterval={5000}
                lazyLoad
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Search Bar Section */}
      <Container sx={{ mb: 6 }}>
        <Box
          component="form"
          onSubmit={handleSearchSubmit}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 4,
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search for perfumes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 0 }, width: { xs: '100%', sm: '400px' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" size="large" type="submit">
            Search
          </Button>
        </Box>
      </Container>

{/* === Enhanced Categories Section === */}
<Container sx={{ mb: 6 }}>
  <Typography
    variant="h4"
    align="center"
    gutterBottom
    sx={{
      fontFamily: 'Playfair Display, serif',
      fontWeight: 'bold',
      color: '#333',
    }}
  >
    Shop by Category
  </Typography>
  {loadingCategories ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : errorCategories ? (
    <Alert severity="error">{errorCategories}</Alert>
  ) : categories.length === 0 ? (
    <Alert severity="info">No categories available at the moment.</Alert>
  ) : (
    <Grid container spacing={4} sx={{ mt: 2 }}>
      {categories.map((category) => (
        <Grid item xs={12} sm={6} md={4} key={category.id}>
          <Card
            component={Link}
            to={`/products?category=${encodeURIComponent(category.name)}`}
            sx={{
              textDecoration: 'none',
              borderRadius: 2,
              boxShadow: 3,
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: 6,
              },
            }}
          >
            <CardMedia
              component="img"
              image={
                category.imageUrl ||
                'https://lemda.co.uk/dubai_api/uploads/category_placeholder.png'
              }
              alt={category.name}
              sx={{ height: 200 }}
            />
            <CardContent>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: 'bold', color: '#333' }}
              >
                {category.name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )}
</Container>
{/* === End of Categories Section === */}


      {/* Featured Products Section */}
      <Container sx={{ mb: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Featured Products
        </Typography>
        {loadingFeatured ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : errorFeatured ? (
          <Alert severity="error">{errorFeatured}</Alert>
        ) : featuredProducts.length === 0 ? (
          <Alert severity="info">No featured products available at the moment.</Alert>
        ) : (
          <Grid container spacing={4} sx={{ mt: 2 }}>
            {featuredProducts.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`https://lemda.co.uk/dubai_api/uploads/${product.image}`}
                    alt={product.name}
                    sx={{ height: 250 }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://lemda.co.uk/dubai_api/uploads/placeholder.png';
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {`${product.brand} - ${product.name}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {product.description.length > 100
                        ? `${product.description.substring(0, 100)}...`
                        : product.description}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      LKR {product.base_price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={Link}
                      to={`/products/${product.id}`}
                      startIcon={<ShoppingCartIcon />}
                    >
                      View Details
                    </Button>
                    <IconButton color="secondary" aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      {/* Latest Arrivals Section */}
      <Container sx={{ mb: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Latest Arrivals
        </Typography>
        {loadingLatest ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : errorLatest ? (
          <Alert severity="error">{errorLatest}</Alert>
        ) : latestProducts.length === 0 ? (
          <Alert severity="info">No latest products available at the moment.</Alert>
        ) : (
          <Grid container spacing={4} sx={{ mt: 2 }}>
            {latestProducts.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`https://lemda.co.uk/dubai_api/uploads/${product.image}`}
                    alt={product.name}
                    sx={{ height: 250 }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://lemda.co.uk/dubai_api/uploads/placeholder.png';
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {`${product.brand} - ${product.name}`}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      LKR {product.base_price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={Link}
                      to={`/products/${product.id}`}
                      startIcon={<ShoppingCartIcon />}
                    >
                      View Details
                    </Button>
                    <IconButton color="secondary" aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      {/* Testimonials Section */}
      <Container sx={{ mb: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          What Our Customers Say
        </Typography>
        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                "The best perfume shopping experience I've ever had!"
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                - Sarah L.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                "Exceptional service and an amazing selection."
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                - Michael B.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                "I found my signature scent here, highly recommend."
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                - Emily R.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Newsletter Subscription Section */}
      <Container sx={{ mb: 6 }}>
        <Box
          sx={{
            backgroundColor: 'grey.200',
            padding: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Join Our Newsletter
          </Typography>
          <Typography variant="body1" gutterBottom>
            Subscribe to receive exclusive offers and updates.
          </Typography>
          <Box
            component="form"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              flexDirection: { xs: 'column', sm: 'row' },
            }}
            onSubmit={(e) => {
              e.preventDefault();
              // Implement subscription logic here
              alert('Subscribed!');
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              required
              sx={{
                mr: { sm: 2 },
                mb: { xs: 2, sm: 0 },
                width: { xs: '100%', sm: '300px' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" color="primary" size="large" type="submit">
              Subscribe
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default HomePage;
