// src/components/ReviewSection.js
import React from 'react';
import { Typography } from '@mui/material';

function ReviewSection({ productId }) {
  // Placeholder content
  return (
    <Typography variant="body1">
      Customer reviews will be displayed here.
    </Typography>
  );
}

export default ReviewSection;
