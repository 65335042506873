// src/pages/LoginPage.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

function LoginPage() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://lemda.co.uk/dubai_api/login.php', formData)
      .then(response => {
        if (response.data.success) {
          setUser(response.data.user);
          navigate('/'); // Redirect to home after successful login
        } else {
          alert('Login failed: ' + response.data.error);
        }
      })
      .catch(error => {
        console.error('Login error:', error);
      });
  };

  return (
    <div className="login-page container my-5">
      <h2 className="mb-4">Log In</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="btn btn-dark">Log In</button>
      </form>
    </div>
  );
}

export default LoginPage;
