// src/components/VariationSelector.js

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  width: '100%',
  marginTop: theme.spacing(2),
}));

const VariationDetailsCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#f9f9f9',
}));

function VariationSelector({ variations, selectedVariation, setSelectedVariation }) {
  const handleChange = (event) => {
    const variationId = event.target.value;
    const variation = variations.find((v) => v.id === variationId);
    setSelectedVariation(variation);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Typography variant="h6" component="label" htmlFor="variation-selector">
        Select Variation:
      </Typography>
      <StyledFormControl variant="outlined" id="variation-selector">
        <InputLabel id="variation-label">Variation</InputLabel>
        <Select
          labelId="variation-label"
          id="variation-select"
          value={selectedVariation ? selectedVariation.id : ''}
          onChange={handleChange}
          label="Variation"
          displayEmpty
        >
          <MenuItem value="" disabled>
            <em>Select a variation</em>
          </MenuItem>
          {variations.map((variation) => (
            <MenuItem key={variation.id} value={variation.id}>
              {`${variation.variation_name} - LKR ${variation.price}`}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      {/* Display selected variation details */}
      {selectedVariation && (
        <VariationDetailsCard>
          <CardHeader
            title={`Variation Details: ${selectedVariation.variation_name}`}
            titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>SKU:</strong> {selectedVariation.sku}
                </Typography>
                <Typography variant="body1">
                  <strong>Price:</strong> LKR {selectedVariation.price}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Stock:</strong> {selectedVariation.stock}
                </Typography>
                {/* Add more details if necessary */}
              </Grid>
            </Grid>
          </CardContent>
        </VariationDetailsCard>
      )}

      {/* Handle no variations available */}
      {!selectedVariation && variations.length === 0 && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          No variations available for this product.
        </Typography>
      )}
    </Box>
  );
}

export default VariationSelector;
