// src/components/MobileBottomNav.js
import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper, Badge, Tooltip } from '@mui/material';
import {
  Home as HomeIcon,
  Favorite as FavoriteIcon,
  ShoppingCart as ShoppingCartIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Define the height of the Bottom Navigation Bar
const NAV_HEIGHT = 56;

// Styled Components
const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  width: '100%',
  height: NAV_HEIGHT,
  backgroundColor: '#f5a067', // Dark background
  color: '#ffffff', // Light text by default
  '.Mui-selected': {
    color: '#ff4081', // Accent color for active tab
  },
  '.MuiBottomNavigationAction-label': {
    fontSize: '0.75rem',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar + 1, // Ensure it stays above other components
}));

function MobileBottomNav() {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    if (location.pathname === '/') return 'home';
    if (location.pathname.startsWith('/favorites')) return 'favorites';
    if (location.pathname.startsWith('/cart')) return 'cart';
    if (location.pathname.startsWith('/profile')) return 'profile';
    return 'home';
  };

  const [value, setValue] = React.useState(getActiveTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 'home':
        navigate('/');
        break;
      case 'favorites':
        navigate('/favorites');
        break;
      case 'cart':
        navigate('/cart');
        break;
      case 'profile':
        navigate('/profile');
        break;
      default:
        navigate('/');
    }
  };

  // Example: Fetch total cart items from context or props
  const totalCartItems = 3; // Replace with actual data

  return (
    <StyledPaper elevation={10}>
      <StyledBottomNavigation value={value} onChange={handleChange}>
        <Tooltip title="Home" arrow>
          <BottomNavigationAction
            label="Home"
            value="home"
            icon={<HomeIcon fontSize="large" />}
            aria-label="Home"
          />
        </Tooltip>
        <Tooltip title="Favorites" arrow>
          <BottomNavigationAction
            label="Favorites"
            value="favorites"
            icon={<FavoriteIcon fontSize="large" />}
            aria-label="Favorites"
          />
        </Tooltip>
        <Tooltip title="Cart" arrow>
          <BottomNavigationAction
            label="Cart"
            value="cart"
            icon={
              <Badge badgeContent={totalCartItems} color="secondary">
                <ShoppingCartIcon fontSize="large" />
              </Badge>
            }
            aria-label="Cart"
          />
        </Tooltip>
        <Tooltip title="Profile" arrow>
          <BottomNavigationAction
            label="Profile"
            value="profile"
            icon={<AccountCircleIcon fontSize="large" />}
            aria-label="Profile"
          />
        </Tooltip>
      </StyledBottomNavigation>
    </StyledPaper>
  );
}

export default MobileBottomNav;
