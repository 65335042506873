// src/components/SpecificationsTable.js
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
} from '@mui/material';

function SpecificationsTable({ specifications }) {
  if (!specifications || Object.keys(specifications).length === 0) {
    return <p>No specifications available.</p>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="specifications table">
        <TableBody>
          {Object.entries(specifications).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SpecificationsTable;
