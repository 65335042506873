// src/pages/RegisterPage.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { UserContext } from '../contexts/UserContext';

// Styled Components
const FormContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

function RegisterPage() {
  const { cart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success', // 'success' | 'error'
    message: '',
  });
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Form validation
  const validateForm = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      phone,
      password,
      confirm_password,
      address,
      city,
      state,
      postal_code,
      country,
    } = formData;

    if (
      !first_name ||
      !last_name ||
      !username ||
      !email ||
      !phone ||
      !password ||
      !confirm_password ||
      !address ||
      !city ||
      !state ||
      !postal_code ||
      !country
    ) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Please fill in all required fields.',
      });
      return false;
    }

    // Email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Please enter a valid email address.',
      });
      return false;
    }

    // Password match
    if (password !== confirm_password) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Passwords do not match.',
      });
      return false;
    }

    // Password strength (minimum 6 characters)
    if (password.length < 6) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Password should be at least 6 characters long.',
      });
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    // Prepare data to send (excluding confirm_password)
    const dataToSend = { ...formData };
    delete dataToSend.confirm_password;

    axios
      .post('https://lemda.co.uk/dubai_api/register.php', dataToSend)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'Registration successful! Redirecting to login...',
          });
          // Optionally, reset form
          setFormData({
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            confirm_password: '',
            address: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
          });
          // Redirect after a delay
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: response.data.error || 'Registration failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'An error occurred during registration. Please try again later.',
        });
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <FormContainer elevation={3}>
        <Typography component="h1" variant="h5" align="center" gutterBottom>
          Create an Account
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="first_name"
                required
                fullWidth
                id="first_name"
                label="First Name"
                autoFocus
                value={formData.first_name}
                onChange={handleChange}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="last_name"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </Grid>
            {/* Username */}
            <Grid item xs={12}>
              <TextField
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                value={formData.username}
                onChange={handleChange}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                name="email"
                required
                fullWidth
                id="email"
                label="Email Address"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12}>
              <TextField
                name="phone"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
              />
            </Grid>
            {/* Password */}
            <Grid item xs={12}>
              <TextField
                name="password"
                required
                fullWidth
                id="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Confirm Password */}
            <Grid item xs={12}>
              <TextField
                name="confirm_password"
                required
                fullWidth
                id="confirm_password"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirm_password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12}>
              <TextField
                name="address"
                required
                fullWidth
                id="address"
                label="Address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            {/* City */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="city"
                required
                fullWidth
                id="city"
                label="City"
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            {/* State */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="state"
                required
                fullWidth
                id="state"
                label="State/Province"
                value={formData.state}
                onChange={handleChange}
              />
            </Grid>
            {/* Postal Code */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="postal_code"
                required
                fullWidth
                id="postal_code"
                label="Postal Code"
                value={formData.postal_code}
                onChange={handleChange}
              />
            </Grid>
            {/* Country */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="country"
                required
                fullWidth
                id="country"
                label="Country"
                value={formData.country}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {/* Submit Button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
          </Button>
          {/* Redirect to Login */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Typography variant="body2">
                Already have an account?{' '}
                <Button
                  component={RouterLink}
                  to="/login"
                  variant="text"
                  color="primary"
                >
                  Login
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </FormContainer>
      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default RegisterPage;
