// src/components/RelatedProductsCarousel.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styled Components
const CarouselContainer = Box;

const ProductCard = Card;

function RelatedProductsCarousel({ categoryId }) {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(
          `https://lemda.co.uk/dubai_api/get_related_products.php?category_id=${categoryId}`
        );
        setRelatedProducts(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching related products:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchRelatedProducts();
  }, [categoryId]);

  // Carousel settings
  const settings = {
    dots: true,
    infinite: relatedProducts.length > 4, // Infinite loop only if more than 4 products
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280, // Large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960, // Medium screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: true, // Show navigation arrows
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6" color="error">
          Failed to load related products.
        </Typography>
      </Box>
    );
  }

  if (relatedProducts.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">No related products found.</Typography>
      </Box>
    );
  }

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {relatedProducts.map((product) => (
          <Box key={product.id} sx={{ px: 1 }}>
            <ProductCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200"
                image={`https://lemda.co.uk/dubai_api/uploads/${product.image}`}
                alt={product.name}
                sx={{ objectFit: 'contain', p: 2 }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1" component="div" gutterBottom noWrap>
                  {product.name}
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  ${parseFloat(product.price).toFixed(2)}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => navigate(`/product/${product.id}`)}
                >
                  View Details
                </Button>
              </CardActions>
            </ProductCard>
          </Box>
        ))}
      </Slider>
    </CarouselContainer>
  );
}

RelatedProductsCarousel.propTypes = {
  categoryId: PropTypes.number.isRequired,
};

export default RelatedProductsCarousel;
