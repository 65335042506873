// src/pages/ProfilePage.js
import React, { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';

function ProfilePage() {
  const { user, setUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    phone: user.phone || '',
    address: user.address || '',
    city: user.city || '',
    state: user.state || '',
    postal_code: user.postal_code || '',
    country: user.country || '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement update profile API call
    axios.post(`https://lemda.co.uk/dubai_api/update_profile.php`, {
      user_id: user.id,
      ...formData
    })
    .then(response => {
      if (response.data.success) {
        setUser(response.data.user);
        alert('Profile updated successfully!');
      } else {
        alert('Error updating profile: ' + response.data.error);
      }
    })
    .catch(error => {
      console.error('Update profile error:', error);
    });
  };

  return (
    <Container className="my-5">
      <h2 className="mb-4">My Profile</h2>
      <Form onSubmit={handleSubmit}>
        {/* First Name */}
        <Form.Group className="mb-3" controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter first name"
            value={formData.first_name}
            onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
            required
          />
        </Form.Group>

        {/* Last Name */}
        <Form.Group className="mb-3" controlId="formLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter last name"
            value={formData.last_name}
            onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
            required
          />
        </Form.Group>

        {/* Email */}
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />
        </Form.Group>

        {/* Phone */}
        <Form.Group className="mb-3" controlId="formPhone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            required
          />
        </Form.Group>

        {/* Address */}
        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="1234 Main St"
            value={formData.address}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            required
          />
        </Form.Group>

        {/* City */}
        <Form.Group className="mb-3" controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            required
          />
        </Form.Group>

        {/* State */}
        <Form.Group className="mb-3" controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            placeholder="State"
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            required
          />
        </Form.Group>

        {/* Postal Code */}
        <Form.Group className="mb-3" controlId="formPostalCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Postal Code"
            value={formData.postal_code}
            onChange={(e) => setFormData({ ...formData, postal_code: e.target.value })}
            required
          />
        </Form.Group>

        {/* Country */}
        <Form.Group className="mb-3" controlId="formCountry">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Country"
            value={formData.country}
            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
            required
          />
        </Form.Group>

        <Button variant="dark" type="submit">
          Update Profile
        </Button>
      </Form>
    </Container>
  );
}

export default ProfilePage;
